@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Raleway:wght@100;200;300;500&display=swap");

:root {
  --blue: #075985;
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

.Tab-header {
  font-family: Raleway;
  font-weight: 200;
  font-size: 6vh;
}

@media screen and (max-width: 661px) {
  .Tab-header {
    font-size: 4.5vh;
  }
}

.Tab-para {
  font-family: Raleway;
  font-weight: 400;
}

/* About Page */
.Resume {
  font-family: Raleway;
  font-weight: 500;
  font-size: 2.5vh;
  color: #075985;
  border: 2px solid #075985;
  border-radius: 10px;
  transition: ease-out 0.3s;
  display: inline-block;
}

.Resume:hover {
  transform: scale(1.1);
  color: rgb(255, 223, 181);
  border: 2px solid rgb(255, 223, 181);
}

.Welcome:hover {
  color: rgb(255, 223, 181);
}

.About-icon {
  font-size: 1.5rem;
  display: inline-block;
  transition: ease-out 0.3s;
}

.About-icon:hover {
  transform: scale(1.3);
}

/* Exp Page */

.Exp-titles {
  font-family: Raleway;
  font-weight: 500;
}

.Exp-info {
  font-family: Raleway;
  font-weight: 500;
}

.Exp-icons {
  font-size: 3.5rem;
}

.Exp-icons:hover {
  animation: bounce 1s infinite;
}

.Exp-div {
  animation: fadein 1s;
  transition: 0.3s ease-out;
}

@media only screen and (max-width: 700px) {
  .Exp-icons {
    font-size: 2.5rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* Projects Page */

.Proj-text {
  font-family: Raleway;
}

/* .Modal-text {
  animation: fadein 1s;
} */

.hold-size {
  width: auto;
}

@media only screen and (min-width: 800px) {
  .hold-size {
    width: 525px;
  }
}

/* .Proj-fade-right {
  transform: translateX(80%);
  transition: all 1s linear;
  animation: fadeout 1s;
  animation-fill-mode: forwards;
}

.Proj-fade-left {
  transform: translateX(-80%);
  transition: all 1s linear;
  animation: fadeout 1s;
  animation-fill-mode: forwards;
} */
/* 
.Proj-fade-in {
  transition: all 1s linear;
  animation: fadein 1s;
}

@media only screen and (max-width: 700px) {
  .Proj-fade-right {
    transform: translateX(0);
    transition: none;
    animation: none;
    animation-fill-mode: none;
  }

  .Proj-fade-left {
    transform: translateX(0);
    transition: none;
    animation: none;
    animation-fill-mode: none;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

/* Skills page */

.Skills-icon {
  font-size: 3.4rem;
  transition: 0.2s ease-in-out;
}

.Skills-icon:hover {
  transform: scale(1.2);
}

.Skills-info {
  font-family: Raleway;
}

.Skills-info-header {
  font-family: Raleway;
}

/* Contacts Page */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Contact-statement {
  font-family: Raleway;
}
